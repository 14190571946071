import React, { useState } from 'react';
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';
import { useHistory, } from 'react-router-dom';
import lg_cloud from '../../assets/img/logos/AVAP_Cloud_negativo.svg'
import lg_market from '../../assets/img/logos/AVAP_Marketplace_white.svg'
import lg_publisher from '../../assets/img/logos/AVAP_API-Publisher_white.svg'
import lg_avs from '../../assets/img/logos/AVAP_VS_white.svg'
import lg_gateway from '../../assets/img/logos/AVAP_Gateway_white.svg'
import { Accordion, Card, Button } from 'react-bootstrap';

const Pricing = () => {
    // Estado para manejar la pestaña activa
    const [activeTab, setActiveTab] = useState('cloud');
    const history = useHistory();

    return (
        <>
            <FlNav />

            {/* Contenedor principal */}
            <div id="pricing" className='fl-cloud-product'>

                {/* Encabezado */}
                <div className="w-100 roadmapHeader text-center mb-5">
                    <div className="fl-title">Pricing &amp; Plans</div>
                    {/* Barra de pestañas */}
                    <ul className="nav nav-pills justify-content-center pillsWrapper">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'cloud' ? 'active' : ''}`}
                                onClick={() => setActiveTab('cloud')}
                            >
                                <img src={lg_cloud} alt="" />
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'virtual' ? 'active' : ''}`}
                                onClick={() => setActiveTab('virtual')}
                            >
                                <img src={lg_avs} alt="" />
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'gateway' ? 'active' : ''}`}
                                onClick={() => setActiveTab('gateway')}
                            >
                                <img src={lg_gateway} alt="" />
                            </button>
                        </li>
                    </ul>
                </div>

                {/* Contenido que cambia según la pestaña seleccionada */}
                <div className="container  bg-black ">
                    {activeTab === 'cloud' && (
                        <>
                            {/* Sección descriptiva */}
                            <div className="text-center mb-4">
                                <img src={lg_cloud} alt="" className='mt-4 mb-6' />
                                <h2>A Native Management Service</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam
                                    nonumy eirmod tempor incididunt ut labore et dolore magna aliquyam erat.
                                    Sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                    Stet clita kasd gubergren, no sea takimata.
                                </p>
                            </div>

                            {/* Sección de planes */}

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        {/* <img src={lg_cloud} alt="" /> */}
                                        <div className="titleBox alter">
                                            AVAP CLOUD PROFESSIONAL
                                        </div>
                                        <div>
                                            <p className="price">
                                                $00.00
                                            </p>
                                            <span className='priceInfo'>Per user/month</span>
                                        </div>

                                        <div className="descBox">
                                            Membership that allows you to explore and start taking advantage of AVAP technology.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://developer.avapframework.com"><span className="btnInner">  Start for Free</span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        {/* <img src={lg_cloud} alt="" /> */}
                                        <div className="titleBox alter">
                                            AVAP CLOUD BUSINESS
                                        </div>
                                        <div>
                                            <p className="price">
                                                $250.00
                                            </p>
                                            <span className='priceInfo'>Per user/month</span>
                                        </div>
                                        <div className="descBox">
                                            Pre-configured AVAP infrastructure deployment, enabling you to concentrate on developing and publishing your APIs.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://developer.avapframework.com"><span className="btnInner">  Start for Free</span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        {/* <img src={lg_cloud} alt="" /> */}
                                        <div className="titleBox alter">
                                            AVAP CLOUD ENTERPRISE
                                        </div>
                                        <div>
                                            <p className="price">
                                                Custom Pricing
                                            </p>
                                            <span className='priceInfo'>Billed Annually</span>
                                        </div>
                                        <div className="descBox">
                                            Maintain full control over the deployment of AVAP infrastructure, tailored for corporations.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://developer.avapframework.com"><span className="btnInner"> Contact Us</span></a>
                                    </div>
                                </div>
                            </div>


                            {/* Sección de Deployment Features */}
                            <div className="mt-6 mb-5 row">
                                <div className="col">
                                    <h3 className="text-center mb-4">Deployment Features</h3>
                                </div>
                            </div>
                            <div className="row">

                                <div className="divTable pricingTable">
                                    <div className="divTableBody">

                                        {/* Fila 1: Encabezados */}
                                        <div className="divTableRow">
                                            <div className="divTableCell relative">
                                                <img className='logoTable' src={lg_cloud} alt="AVAP Cloud" />
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>On Premise</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Hybrid</span>
                                            </div>
                                        </div>

                                        {/* Fila 2: Descripción */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                        </div>

                                        {/* Fila 3: Gateway Services */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Gateway Services</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                        {/* Fila 4: Organizations */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Organizations</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                        {/* Fila 5: API Requests */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>API Requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                        {/* Fila 6: Projects */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                        </div>

                                        {/* Fila 7: Developers */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                        </div>

                                        {/* Fila 8: Request */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Request</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                        </div>

                                        {/* Fila 9: Storage */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Storage</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                        </div>

                                        {/* Fila 10: Lorem ipsum */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✓</span>
                                            </div>
                                        </div>

                                        {/* Fila 11: Lorem ipsum */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✓</span>
                                            </div>
                                        </div>

                                        {/* Fila 12: Lorem ipsum */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✓</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <img className='logoTable' src={lg_publisher} alt="AVAP Cloud" />
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✕</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>✓</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <img className='logoTable' src={lg_market} alt="AVAP Cloud" />
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Publicación API Products</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='bold'>Comercialización</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {activeTab === 'virtual' && (
                        <div>
                            {/* Estructura y secciones equivalentes a la anterior,
                  pero con contenido placeholder para AVAP Virtual Server */}
                            <h2 className="text-center">AVAP Virtual Server</h2>
                            <p className="text-center">
                                Aquí iría la información específica de AVAP Virtual Server...
                            </p>
                            {/* Repite secciones de planes, features, etc. con placeholders */}
                        </div>
                    )}

                    {activeTab === 'gateway' && (
                        <div>
                            {/* Estructura y secciones equivalentes a la anterior,
                  pero con contenido placeholder para AVAP Gateway Advanced */}
                            <h2 className="text-center">AVAP Gateway Advanced</h2>
                            <p className="text-center">
                                Aquí iría la información específica de AVAP Gateway Advanced...
                            </p>
                            {/* Repite secciones de planes, features, etc. con placeholders */}
                        </div>
                    )}
                </div>

                {/* Sección FAQ */}
                <div className="container mt-5" id="faq">
                    <h3 className="text-center mb-4">FAQ</h3>

                    <Accordion defaultActiveKey="0" className='blackTheme'>
                        {/* Pregunta 1 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="0"
                                >
                                    What payment methods do you offer?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed diam nonumy eirmod tempor incididunt...
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>
                        {/* Pregunta 2 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="1"
                                >
                                    How can I upgrade/downgrade/cancel my plan?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    Lorem ipsum dolor sit amet, consectetur...
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>
                        {/* Pregunta 3 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="2"
                                >
                                    How long are subscription terms?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    Lorem ipsum dolor sit amet, consectetur...
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </div>

            <FlFooter />
        </>
    );
};

export default Pricing;
